import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Main, Login } from './components/pages';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CommonHeader } from './components/widgets';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { LocaleProvider } from 'antd';
import './app.scss';
import { connect } from 'react-redux';

import { LoginAction } from './redux/actions';

const {
    handleLogoutAction
} = LoginAction;

const mapStateToProps = (state) => {
    return {
        loginStatus: state.login.loginStatus,
        user: state.personal.user || {}
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogout: () => {
            dispatch(handleLogoutAction());
        }
    };
};

class App extends Component {
    static propTypes = {
        loginStatus: PropTypes.bool
    };

    static defaultProps = {
        loginStatus: false
    };

    render () {
        const app = {
            appName: '用户中心',
            appLink: '#',
            onLogout: () => {
                this.props.handleLogout();
            }
        };
        let {loginStatus, user} = this.props;
        // if ()
        return (
            <LocaleProvider locale={zhCN}>
                <div>
                    <CommonHeader {...app}
                                  isLogin={loginStatus}
                                  userName={user.name}
                                  avatar={user.headImg}/>
                    <HashRouter>
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Route path="/" render={() => {
                                return !loginStatus ? <Redirect path="/" to={{pathname: '/login'}}/> : <Main/>;
                                // return <Main/>;
                            }}/>
                            {/*<Redirect path="/" to={{pathname: '/login'}}/>*/}
                        </Switch>
                    </HashRouter>
                </div>
            </LocaleProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);