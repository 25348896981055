import { fetch, config, storage } from '../../lib';
import { push } from 'react-router-redux';
import CommonAction from './common-action';

const {localStorage} = storage;
const {
    createStatus,
    handleError,
    fetchStatus
} = CommonAction;

const {ERROR, LOADING, FINISH} = createStatus('LOGIN');
const LOGOUT = 'LOGOUT';
const GET_APP_LOGIN = 'GET_APP_LOGIN';
const REDIRECT_OATH = 'REDIRECT_OATH';

function handleLoginAction (user, appid, isRemember) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        localStorage.setItem('remember-user', user);
        return fetch(config.login, user).then(data => {
            localStorage.setItem('login-user', data.data);
            return dispatch(fetchStatus(data.data, FINISH));
        }).then(v => {
            if (appid) {
                return dispatch(fetchStatus(null, REDIRECT_OATH));
            }
            return dispatch(push('/personal'));
        }).catch(error => {
            console.log(error);
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleLogoutAction () {
    return (dispatch, getState) => {
        return fetch(config.logout, {}, {method: 'GET'}).then(data => {
            localStorage.removeItem('login-user');
            return dispatch(fetchStatus(null, LOGOUT));
        }).then(v => {
            return dispatch(push('/logout'));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleGetAppAction (appid) {
    return (dispatch, getState) => {
        return fetch(`${config.getApp2Login}${appid}`, {}, {method: 'GET'}).then(data => {
            return dispatch(fetchStatus(data.data, GET_APP_LOGIN));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

export default {
    LOADING,
    FINISH,
    ERROR,
    LOGOUT,
    GET_APP_LOGIN,
    REDIRECT_OATH,
    handleLoginAction,
    handleLogoutAction,
    handleGetAppAction
};
