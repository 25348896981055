import React, { Component } from 'react';
import { Collapse, Input, Avatar, Pagination, Switch, message } from 'antd';
import { Label } from '../../widgets';
import { connect } from 'react-redux';
import { AuthAction } from '../../../redux/actions';

const Panel = Collapse.Panel;
const Search = Input.Search;

const {
    handleGetAppListAction,
    handleGetUserListAction,
    handleEditUserAppAction,
    handleDeleteUserAppAction
} = AuthAction;

const mapStateToProps = (state) => {
    const {auth} = state;
    return {
        appList: auth.appList,
        userList: auth.userList,
        pageTotal: auth.pageTotal
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleGetAppList: () => {
            dispatch(handleGetAppListAction());
        },
        handleGetUserList: (limit, offset, keyword) => {
            dispatch(handleGetUserListAction(limit, offset, keyword));
        },
        handleEditUserApp: (uid, appid, fn) => {
            dispatch(handleEditUserAppAction(uid, appid, fn));
        },
        handleDeleteUserApp: (uid, appid, fn) => {
            dispatch(handleDeleteUserAppAction(uid, appid, fn));
        }
    };
};

class AuthManager extends Component {

    constructor (props) {
        super(props);
        this.state = {
            pageConfig: {
                size: 'small',
                total: this.props.pageTotal,
                showSizeChanger: true,
                showQuickJumper: true,
                current: 1,
                pageSize: 10,
            }
        };
    }

    componentWillReceiveProps (n, o) {
        const pageConfig = this.state.pageConfig;
        pageConfig.total = n.pageTotal;
        this.setState({pageConfig});
    }

    componentDidMount () {
        this.props.handleGetUserList(this.state.pageConfig.pageSize, 0, '');
        this.props.handleGetAppList();
    }

    handlePageChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetUserList(e.pageSize, (e.current - 1) * e.pageSize, '');
    };

    handlePageSizeChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetUserList(e.pageSize, 0, '');
    };

    handleSwitchChange = (uid, appid, val) => {
        const refresh = (msg) => {
            return () => {
                message.success(msg);
                this.props.handleGetUserList(this.state.pageConfig.pageSize, 0, '');
            };
        };
        if (val) {
            this.props.handleEditUserApp(uid, appid, refresh('授权成功'));
        } else {
            this.props.handleDeleteUserApp(uid, appid, refresh('取消授权成功'));
        }
    };

    handleSearch = (kw) => {
        this.props.handleGetAppList(this.state.pageConfig.pageSize, 0, kw);
    };

    renderPanelHeader (val) {
        const imageUrl = `/web/image/view/${val.headImg}`;
        return (
            <div key={val.id}>
                <Avatar icon='user' style={{marginRight: '12px'}} size='large'
                        src={imageUrl}/>
                <Label>账号：{val.account}</Label>
                <Label>昵称：{val.name}</Label>
                <Label>授权应用数目：{val.app.length}个</Label>
            </div>
        );
    }

    renderPanelBody (user) {
        const userAppIds = user.app.map(v => v.id);
        const {appList} = this.props;
        return appList.map(app => {
            return (
                <div className="row app-list" key={app.id}>
                    <Label className="key" suffix_icon={app.icon}>{app.name}</Label>
                    <Label className="value">{userAppIds.includes(app.id) ? '开启' : '关闭'}</Label>
                    {
                        app.disabled ? '' :
                            <Switch className='modify-btn' type="primary"
                                    defaultChecked={userAppIds.includes(app.id)} shape="circle" size='small'
                                    onChange={this.handleSwitchChange.bind(this, user.id, app.id)}
                            />
                    }
                </div>
            );
        });
    }

    render () {
        const {keyword, pageConfig} = this.state;
        const {userList, appList} = this.props;

        return (
            <div className=' page-container component-auth'>
                <div className="cat-info">
                    <div className="cat-name">
                        授权管理
                        <Search
                            className='search-ipt'
                            placeholder="请输入要搜索的内容"
                            onSearch={this.handleSearch}
                            style={{width: 240}}
                        />
                    </div>
                </div>
                <div>
                    <Collapse bordered={false}>
                        {
                            userList.map(val => {
                                return (
                                    <Panel key={val.id} header={this.renderPanelHeader(val)}>
                                        {
                                            this.renderPanelBody(val)
                                        }
                                    </Panel>
                                );
                            })
                        }
                    </Collapse>,
                </div>
                <div>
                    <Pagination className='auth-pagination'
                                size="small"
                                {...pageConfig}
                                onChange={this.handlePageChange}
                                onShowSizeChange={this.handlePageSizeChange}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthManager);