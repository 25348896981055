import React, { Component } from 'react';
import { Input, Button, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { LoginAction } from '../../../redux/actions';
import { storage } from '../../../lib';
import queryString from 'query-string';

const {localStorage} = storage;
const {
    handleLoginAction,
    handleGetAppAction
} = LoginAction;

const mapStateToProps = (state) => {
    const {login} = state;
    return {
        error: login.error,
        app: login.app,
        redirect: login.redirect
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogin: (user, appid, isChecked) => {
            dispatch(handleLoginAction(user, appid, isChecked));
        },
        handleGetApp: (appid) => {
            dispatch(handleGetAppAction(appid));
        }
    };
};

class Login extends Component {
    constructor (props) {
        super(props);
        const params = queryString.parse(this.props.location.search);
        this.state = {
            info: {
                title: 'Fishelly Idx',
                sub_title: 'Web Front End'
            },
            user: localStorage.getItem('remember-user') || {
                account: '',
                password: ''
            },
            appid: params.appid,
            errorMessage: '',
            isRemember: !!localStorage.getItem('remember-user')
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleIptChange = this.handleIptChange.bind(this);
    }

    componentDidMount () {
        if (this.state.appid) {
            this.props.handleGetApp(this.state.appid);
        }
    }

    componentWillReceiveProps (n, o) {
        if (n.redirect) {
            this.refs.form.submit();
        }
    }

    checkUser () {
        const {user} = this.state;
        let errorMessage = '';
        if (!user.account) {
            errorMessage = '请输入登录账号';
        } else if (!user.password) {
            errorMessage = '请输入登录密码';
        }
        this.setState({
            errorMessage
        });
        return !errorMessage;
    }

    handleLogin () {
        if (!this.checkUser()) {
            return;
        }
        const {user, appid} = this.state;
        this.props.handleLogin(user, appid, this.props.isRemember);
    }

    handleKeyDown (e) {
        if (e.keyCode === 13) {
            this.handleLogin();
        }
    }

    handleIptChange (key) {
        return (e) => {
            const user = this.state.user;
            user[key] = e.target.value;
            this.setState(user);
        };
    }

    handleCheckBoxChange (e) {
        this.setState({
            isRemember: e.target.checked
        });
    }

    render () {
        const {info, errorMessage, isRemember, user, appid} = this.state;
        const {error, app} = this.props;
        const finalError = errorMessage || error.msg;
        return (
            <div className="login-frame">
                <div className="frame-header">
                    <div className="title">
                        {info.title}
                    </div>
                    <div className="slogen">
                        {info.sub_title}
                    </div>
                </div>
                <form method='post'
                      ref='form'
                      action='/web/user/logined'
                      className="frame-body"
                      onKeyDown={this.handleKeyDown}>
                    <input type="hidden" name='appid' value={appid}/>
                    <div className="row">
                        <Input size='large'
                               defaultValue={user.account}
                               onChange={this.handleIptChange('account')}
                               placeholder="账号"
                               prefix='&#xe693;'/>
                    </div>
                    <div className="row">
                        <Input size='large' defaultValue={user.password} onChange={this.handleIptChange('password')}
                               placeholder="密码" type="password"
                               prefix='&#xe683;'/>
                    </div>
                    <div className="row">
                        <Checkbox defaultChecked={isRemember} onChange={this.handleCheckBoxChange}>记住密码</Checkbox>
                    </div>
                    <div className="row app-tips" style={{display: app ? 'block' : 'none'}}>
                        {app && `登录后前往：${app.name}`}
                    </div>
                    <div className="row error" style={{display: finalError ? 'block' : 'none'}}>
                        {finalError}
                    </div>
                    <div className="row">
                        <Button size='large' block type="primary" onClick={this.handleLogin}>登录</Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);