import { PersonalAction } from '../actions';
import { storage } from '../../lib';

const {localStorage} = storage;
const {
    LOADING,
    ERROR,
    FINISH,
    GET_USER,
    EDIT_USER_FINISH,
    EDIT_APP_FINISH
} = PersonalAction;

function getUser () {
    const loginUser = localStorage.getItem('login-user');
    return loginUser ? loginUser.user : {};
}

const initialState = {
    user: getUser(),
    error: {},
    userApps: [],
    allApps: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case LOADING: {
            return {
                ...state,
                error: {}
            };
        }
        case FINISH: {
            return {
                ...state,
                error: {},
                userApps: action.payload.userApps,
                allApps: action.payload.allApps
            };
        }
        case EDIT_USER_FINISH: {
            return {
                ...state,
                error: {},
                user: {...action.payload}
            };
        }
        case EDIT_APP_FINISH: {
            return {
                ...state,
                error: {},
                userApps: action.payload
            };
        }
        case GET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
    }
    // state.user = state.user ? state.user.user : {};
    return {...state};
}