import loginReducer from './login-reducer';
import personalReducer from './personal-reducer';
import userReducer from './user-reducer';
import appReducer from './app-reducer';
import authReducer from './auth-reducer';


import { combineReducers } from 'redux';

const MyReducers = combineReducers({
    login: loginReducer,
    personal: personalReducer,
    user: userReducer,
    app: appReducer,
    auth: authReducer
});

export default MyReducers;