import { fetch, config, storage } from '../../lib';
import { push } from 'react-router-redux';
import CommonAction from './common-action';

const {localStorage} = storage;

const {
    createStatus,
    handleError,
    fetchStatus
} = CommonAction;

const {ERROR, LOADING, FINISH} = createStatus('USERS');

function handleGetUserListAction (limit, offset, keyword) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(config.getUsers, {
            limit, offset, keyword
        }, {method: 'GET'}).then(v => {
            dispatch(fetchStatus(v.data, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleEditUserAction (data) {

}

function handleAddUserAction (user, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getUsers}`, user).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleDeleteUserAction (uid, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getUsers}${uid}`, {}, {method: 'DELETE'}).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

export default {
    LOADING,
    FINISH,
    ERROR,
    handleAddUserAction,
    handleDeleteUserAction,
    handleEditUserAction,
    handleGetUserListAction
};
