import React, { Component } from 'react';
import {Avatar} from 'antd';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from '../../../lib/classnames';

export default class CommonHeader extends Component {
    static propTypes = {
        appName: PropTypes.string,
        appLink: PropTypes.string,
        avatar: PropTypes.any,
        userName: PropTypes.string,
        onLogout: PropTypes.func,
        isLogin: PropTypes.bool
    };

    static defaultProps = {
        appLink: '#',
        appName: '',
        avatar: '',
        userName: '',
        isLogin: false,
        onLogout: () => {}
    };

    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick ($e) {
        this.props.onLogout && this.props.onLogout($e);
    }

    render () {
        let {appName, className, appLink, avatar, userName, onLogout, isLogin, ...other} = this.props;
        const cls = classNames({
            'common-header': true,
            [className]: className
        });
        if (window.location.href.includes('#/login')){
            isLogin = false;
        }
        return (
            <header className={cls}  {...other}>

                <div className="wrapper">
                    <a href={appLink} className="left-part">{appName}</a>
                    {
                        isLogin ? (
                            <div className="right-part">
                                <Avatar icon='user' className='avatar' src={`/web/image/view/${avatar}`}/>
                                {/*<img alt='avatar' className="avatar" src={}/>*/}
                                <div className="text-item">{userName}</div>
                                <div onClick={this.handleClick} className="icon-item">&#xe628;</div>
                            </div>
                        ) : ''
                    }
                </div>
            </header>

        );
    }
}
