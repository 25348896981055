const presets = {};

const setPreset = function (key, value) {
    presets[key] = value;
};

const modifyExtGetArguments = function (data) {
    if (!(data instanceof Object)) {
        return data;
    }
    Object.keys(presets).forEach((x) => {
        if (!(x in data)) {
            data[x] = presets[x];
        }
    });
    let hasFile = false;
    Object.keys(data).forEach((x) => {
        if (data[x] instanceof window.File) {
            hasFile = true;
        } else if (data[x] instanceof window.Blob) {
            hasFile = true;
        }
    });
    if (hasFile) {
        let formData = new FormData();
        Object.keys(data).forEach((x) => {
            formData.append(x, data[x]);
        });
        return formData;
    }
    return data;
};

function modifyGetArguments (url, data) {
    if (typeof data === 'string') {
        data = JSON.parse(data);
    }
    Object.keys(data).forEach((val, idx) => {
        if (!idx) {
            url = `${url}?${val}=${encodeURIComponent(data[val])}`;
        } else {
            url = `${url}&${val}=${encodeURIComponent(data[val])}`;
        }
    });
    return url;
}

function clearStorage () {
    const cache = localStorage.getItem('remember-user');
    localStorage.clear();
    localStorage.setItem('remember-user', cache);
}

function fetchFunc (url, data, options = {}) {
    const tgOptions = {
        method: 'POST',
        credentials: 'same-origin',
        mode: 'cors',
        headers: {},
        body: JSON.stringify(modifyExtGetArguments(data))
    };
    Object.assign(tgOptions, options);
    tgOptions.headers['Content-Type'] = 'application/json';
    tgOptions.headers['x-csrf-token'] = window._CSRF;
    tgOptions.headers['Accept'] = 'application/json';
    if (tgOptions.method.toLocaleUpperCase() === 'GET') {
        url = modifyGetArguments(url, data);
        delete tgOptions.body;
    }

    return new Promise((resolve, reject) => {
        fetch(url, tgOptions).then(response => {
            const jsonData = response.json();
            if (response.ok) {
                jsonData.then(res => {
                    if (res.code !== 200) {
                        if (res.code === 401) {
                            clearStorage();
                        }
                        reject(res);
                    } else {
                        resolve(res);
                    }
                });
            } else {
                if (response.status === 401) {
                    clearStorage();
                    jsonData.then(res => {
                        reject(res);
                    });
                } else {
                    jsonData.then(res => {
                        reject(res);
                    });
                }
            }
        }).catch(e => {
            console.log('error: ', e);
            reject({code: 10000, msg: '网络错误'});
        });
    });
}

export default fetchFunc;

export {
    setPreset
};