import React, { Component } from 'react';
import { Table, Input, Button, Avatar, Divider, message } from 'antd';
import { ModalHandleApp } from '../../widgets';
import { AppAction } from '../../../redux/actions';
import { connect } from 'react-redux';

const Search = Input.Search;

const {
    handleGetAppListAction,
    handleEditAppAction,
    handleDeleteAppAction,
    handleAddAppAction
} = AppAction;

const mapStateToProps = (state) => {
    const {app} = state;
    return {
        appList: app.appList,
        pageTotal: app.pageTotal
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleGetAppList: (limit, offset, keyword) => {
            dispatch(handleGetAppListAction(limit, offset, keyword));
        },
        handleEditApp: (app, fn) => {
            dispatch(handleEditAppAction(app, fn));
        },
        handleAddApp: (app, fn) => {
            dispatch(handleAddAppAction(app, fn));
        },
        handleDeleteApp: (app, fn) => {
            dispatch(handleDeleteAppAction(app, fn));
        }
    };
};

class AppManager extends Component {

    constructor (props) {
        super(props);
        this.state = {
            pageConfig: {
                size: 'small',
                total: this.props.pageTotal,
                showSizeChanger: true,
                showQuickJumper: true,
                current: 1,
                pageSize: 10,
            },
            modal: {
                title: '添加APP：',
                visible: false,
                okCallback: (v, fn) => {
                },
                cancelCallback: () => {
                    const modal = this.state.modal;
                    modal.visible = false;
                    this.setState({
                        modal
                    });
                }
            }
        };
    }

    componentWillReceiveProps (n, o) {
        const pageConfig = this.state.pageConfig;
        pageConfig.total = n.pageTotal;
        this.setState({pageConfig});
    }

    componentDidMount () {
        this.props.handleGetAppList(this.state.pageConfig.pageSize, 0, '');
    }

    handleDelete = (e) => {
        const uid = e.target.dataset.uid;
        this.props.handleDeleteApp(uid, () => {
            message.success('删除应用成功');
            const pageConfig = this.state.pageConfig;
            pageConfig.current = 1;
            this.setState({
                pageConfig
            });
            this.props.handleGetAppList(pageConfig.pageSize, 0, '');
        });
    };

    handleSearch = (kw) => {
        this.props.handleGetAppList(this.state.pageConfig.pageSize, 0, kw);
    };

    handlePageChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetAppList(e.pageSize, (e.current - 1) * e.pageSize, '');
    };

    handlePageSizeChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetAppList(e.pageSize, 0, '');
    };

    handleBtnClick = (e) => {
        const modal = this.state.modal;
        modal.visible = true;
        modal.okCallback = (val, fn) => {
            this.props.handleAddApp(val, () => {
                const modal = this.state.modal;
                modal.visible = false;
                this.setState({modal});
                fn();
                this.props.handleGetAppList(this.state.pageConfig.pageSize, 0, '');
                message.success(`添加应用成功`);
            });
        };
        this.setState({
            modal
        });
    };

    handleEdit = (record) => {
        const modal = this.state.modal;
        modal.visible = true;
        modal.defaultApp = record;
        modal.okCallback = (val, fn) => {
            this.props.handleEditApp(val, () => {
                const modal = this.state.modal;
                modal.visible = false;
                this.setState({modal});
                fn();
                this.props.handleGetAppList(this.state.pageConfig.pageSize, 0, '');
                message.success(`编辑应用成功`);
            });
        };
        this.setState({
            modal
        });
    };

    render () {

        const columns = [
            {
                title: '图标',
                dataIndex: 'icon',
                key: 'icon',
                width: 80,
                render: (text, v) => {
                    return <span className='icon-font' dangerouslySetInnerHTML={{__html: v.icon}}></span>;
                }
            },
            {
                title: 'appId',
                dataIndex: 'id',
                key: 'id',
                width: 110
            },
            {
                title: 'app名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'app地址',
                dataIndex: 'url',
                key: 'url',
                render: (text, record) => {
                    return (
                        <span>
                          <a href={record.url}>{record.url}</a>
                        </span>
                    );
                }
            },
            {
                title: 'ticket',
                dataIndex: 'ticket',
                key: 'ticket',
            },
            {
                title: '操作',
                key: 'action',
                width: 70,
                render: (text, record) => (
                    <span>
                        <a href="javascript:;" data-uid={record.id} onClick={this.handleEdit.bind(this, record)}>编辑</a>
                        <a href="javascript:;" data-uid={record.id} onClick={this.handleDelete}>删除</a>
                    </span>
                ),
            }
        ];
        const {keyword, pageConfig, modal} = this.state;
        const {appList} = this.props;

        return (
            <div className=' page-container component-user'>
                <div className="cat-info">
                    <div className="cat-name">
                        APP管理
                        <Button onClick={this.handleBtnClick} className='add-btn'>新增App</Button>
                        <Search
                            defaultValue={keyword}
                            className='search-ipt'
                            placeholder="请输入要搜索的内容"
                            onSearch={this.handleSearch}
                            style={{width: 240}}
                        />
                    </div>
                </div>
                <div className="table-wrapper with-table-bordered">
                    <Table rowKey={record => record.id} onChange={this.handlePageChange}
                           onShowSizeChange={this.handlePageSizeChange} pagination={pageConfig}
                           dataSource={appList}
                           columns={columns}/>
                </div>
                <ModalHandleApp {...modal}></ModalHandleApp>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppManager);
