import React, { Component } from 'react';
import { Avatar, Button, Switch, message, Icon, Upload } from 'antd';
import { Label, ModalPrompt } from '../../widgets';
import { connect } from 'react-redux';
import { PersonalAction } from '../../../redux/actions';
import { config } from '../../../lib';

const {
    handleGetUserAppsAction,
    handleGetUserAction,
    handleEditUserInfoAction,
    handleUserAppStatusAction
} = PersonalAction;

const CONSTANCE = {
    account: '账号',
    name: '昵称',
    position: '职位',
    signature: '个性签名',
    introduce: '个人简介',
    headImg: '用户头像'
};

const mapStateToProps = (state) => {
    const {personal} = state;
    return {
        user: personal.user,
        userApps: personal.userApps,
        allApps: personal.allApps,
        error: personal.error
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleGetUserAction: () => {
            dispatch(handleGetUserAction());
            dispatch(handleGetUserAppsAction());
        },
        handleSwitchChangeAction: (id, status) => {
            dispatch(handleUserAppStatusAction(id, status));
        },
        handleEditInfoAction: (user, fn) => {
            dispatch(handleEditUserInfoAction(user, fn));
        }
    };
};

function beforeUpload (file) {
    const isImage = file.type.includes('image');
    if (!isImage) {
        message.error('请上传图片文件');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error('图片不能超过5M');
    }
    return isImage && isLt5M;
}

class Personal extends Component {

    constructor (props) {
        super(props);

        this.state = {
            modal: {
                title: '请输入：',
                visible: false,
                placeholder: '请输入...',
                okCallback: (v, fn) => {
                },
                cancelCallback: () => {
                    const modal = this.state.modal;
                    modal.visible = false;
                    this.setState({
                        modal
                    });
                }
            }
        };
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    handleSwitchChange (id, event) {
        this.props.handleSwitchChangeAction(id, event);
    }

    componentDidMount () {
        this.props.handleGetUserAction();
    }

    computedData (allApps, userApps) {
        const map = new Map();

        allApps.forEach(val => {
            val.disabled = true;
            map.set(val.id, val);
        });
        userApps.forEach(val => {
            const item = map.get(val.appid);
            item.disabled = false;
            item.status = val.status === 1;
            map.set(item.id, item);
        });
        return [...map.values()];
    }

    handleBtnClick (key, val) {
        const modal = this.state.modal;
        modal.placeholder = `请输入${CONSTANCE[key]}`;
        modal.visible = true;
        modal.defaultValue = this.props.user[key];
        modal.okCallback = (val, fn) => {
            const {user, handleEditInfoAction} = this.props;
            user[key] = val;
            handleEditInfoAction(user, () => {
                const modal = this.state.modal;
                modal.visible = false;
                this.setState({modal});
                fn();
                message.success(`修改用户${CONSTANCE[key]}成功`);
            });
        };
        this.setState({
            modal
        });
    }

    handleChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }
        if (info.file.status === 'done') {
            const imgId = info.file.response.data.id;
            const {user, handleEditInfoAction} = this.props;
            user.headImg = imgId;
            handleEditInfoAction(user, () => {
                message.success(`修改用户${CONSTANCE.headImg}成功`);
            });
        }
    };

    getFileData = (file) => {
        const ext = file.name.split('.');
        return {
            name: file.name,
            ext: ext[ext.length - 1]
        };
    };

    render () {
        const {user, allApps, userApps} = this.props;
        const {modal} = this.state;
        const finalApps = this.computedData(allApps, userApps);
        const imageUrl = `/web/image/view/${user.headImg}`;

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'}/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className='component-personal page-container'>
                <div className="avatar-box">
                    <Upload
                        name="file"
                        listType="picture-card"
                        showUploadList={false}
                        data={this.getFileData}
                        action={config.postImage}
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {imageUrl ? <Avatar size={160} src={imageUrl}/> : uploadButton}
                        <div className="avatar-mask">
                            <div className="btn">
                                <span className="icon-font">&#xe604;</span>
                                <span>修改头像</span>
                            </div>
                        </div>
                    </Upload>
                </div>
                <div className="cat-info">
                    <div className="cat-name">关于我~</div>
                </div>
                {
                    Object.keys(CONSTANCE).map(val => {
                        if (val === 'headImg') {
                            return '';
                        }
                        return (
                            <div className="row" key={val}>
                                <Label className="key">{CONSTANCE[val]}</Label>
                                <Label className="value">{user[val]}</Label>
                                {
                                    val === 'account' ?
                                        '' : <Button className='modify-btn'
                                                     onClick={this.handleBtnClick.bind(this, val)}
                                                     type="primary"
                                                     shape="circle" size='small' icon="edit"/>
                                }
                            </div>
                        );
                    })
                }

                <div className="cat-info">
                    <div className="cat-name add-mgt">应用列表~</div>
                </div>

                {
                    finalApps.map((value, key) => {
                        return (
                            <div className="row app-list" key={value.id}>
                                <Label className="key" suffix_icon={value.icon}>{value.name}</Label>
                                <Label className="value">{value.disabled ? '禁用' : (value.status ? <span>开启（<a
                                    target='_blank'
                                    href={`${config.gotoApp}?redirect_url=${value.url}`}>前往</a>）</span> : '关闭')}</Label>
                                {
                                    value.disabled ? '' :
                                        <Switch className='modify-btn' type="primary"
                                                disabled={value.disabled}
                                                defaultChecked={value.status} shape="circle" size='small'
                                                onChange={this.handleSwitchChange.bind(this, value.id)}/>
                                }
                            </div>
                        );
                    })
                }

                <ModalPrompt {...modal}/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Personal);