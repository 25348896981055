import { push } from 'react-router-redux';
import { message } from 'antd';

const LOADING = 'LOADING';
const FINISH = 'FINISH';
const ERROR = 'ERROR';

function createStatus (module) {
    return {
        LOADING: `${LOADING}_${module}`,
        FINISH: `${FINISH}_${module}`,
        ERROR: `${ERROR}_${module}`
    };
}

function fetchStatus (data, type) {
    return {
        type: type,
        payload: data
    };
}

function handleError (status = ERROR) {
    return (dispatch, error, fn) => {
        if (error.code === 401) {
            return dispatch(push('/login'));
        }
        message.error(error.msg);
        return fn ? fn(() => {
            dispatch(fetchStatus(error, status));
        }) : dispatch(fetchStatus(error, status));
    };
}

export default {
    fetchStatus,
    handleError,
    createStatus
};