import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { NavLink } from 'react-router-dom';
import classNames from '../../../lib/classnames';

export default class LeftMenu extends Component {
    static propTypes = {
        menuData: PropTypes.array,
    };

    static defaultProps = {
        menuData: [],
    };

    renderLinkItem (item, idx) {
        return (
            <NavLink key={idx} activeClassName='selected' className="item" to={item.link}>
                {item.icon ? <span className="icon" dangerouslySetInnerHTML={{__html: item.icon}}></span> : ''}
                <span>{item.text}</span>
            </NavLink>
        );
    }

    renderTextItem (item, idx) {
        return (
            <div key={idx} className="item" to={item.link}>
                {item.icon ? <span className="icon" dangerouslySetInnerHTML={{__html: item.icon}}></span> : ''}
                <span>{item.text}</span>
            </div>
        );
    }

    render () {
        const {menuData, className} = this.props;
        const cls = classNames({
            'menu-box': true,
            [className]: className
        });
        return (
            <div className={cls}>
                {
                    menuData.map((val, idx) => {
                        return (
                            (!val.link || !val.isLink) ? this.renderTextItem(val, idx) : this.renderLinkItem(val, idx)
                        );
                    })
                }

            </div>
        );
    }
}
