import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../lib/classnames';
import './style.scss';

export default class Label extends Component {

    static propTypes = {
        text: PropTypes.string,
        suffix_icon: PropTypes.string,
        prefix_icon: PropTypes.string,
        isTrust: PropTypes.bool
    };

    static defaultProps = {
        text: '',
        suffix_icon: '',
        prefix_icon: '',
        isTrust: false
    };

    render () {

        const {text, suffix_icon, prefix_icon, isTrust, children, className, ...other} = this.props;
        const cls = classNames({
            'el-label': true,
            [className]: className
        });

        return (
            <div className={cls} {...other}>
                {
                    !suffix_icon ? '' :
                        (<span className="el-input__suffix">
                        <span className="el-input__suffix-inner">
                            <i className="el-input__icon icon-font" dangerouslySetInnerHTML={{__html: suffix_icon}}></i>
                        </span>
                        </span>)
                }
                {text ? <span>{text}</span> : <span>{children}</span>}
                {
                    !prefix_icon ? '' :
                        (<span className="el-input__prefix">
                            <i className="el-input__icon icon-font" dangerouslySetInnerHTML={{__html: prefix_icon}}></i>
                        </span>)
                }
            </div>
        );
    }
}
