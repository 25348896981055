import React, { Component } from 'react';
import { AuthManager, Personal, UserManager, AppManager } from './index';
import { LeftMenu } from '../widgets';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './style.scss';

class Main extends Component {
    render () {
        const menuData = [
            {
                icon: '&#xe693;',
                text: '个人信息',
                isLink: true,
                type: 'personal',
                link: '/personal'
            },
            {
                icon: '&#xe617;',
                text: '用户管理',
                isLink: true,
                type: 'user',
                link: '/user'
            },
            {
                icon: '&#xe787;',
                text: '应用管理',
                isLink: true,
                type: 'apph',
                link: '/app'
            },
            {
                icon: '&#xe61c;',
                text: '应用授权',
                isLink: true,
                type: 'auth',
                link: '/auth'
            }
        ];
        return (
            <HashRouter path='/a'>
                <div className="container">
                    <div className="left-part">
                        <LeftMenu menuData={menuData}/>
                    </div>
                    <div className="right-part">
                        <Switch path='/'>
                            <Route exact path="/personal" component={Personal}/>
                            <Route path="/user" component={UserManager}/>
                            <Route path="/app" component={AppManager}/>
                            <Route path="/auth" component={AuthManager}/>
                            <Redirect path="/" to={{pathname: '/personal'}}/>
                        </Switch>
                    </div>
                </div>
            </HashRouter>
        );
    }
}

export default Main;