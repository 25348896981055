class StorageUtil {
    constructor (type) {
        if (type) {
            this.storage = window.localStorage;
        } else {
            this.storage = window.sessionStorage;
        }
    }

    check (val) {
        return !(typeof val === 'undefined' || typeof val === 'function') && val;
    }

    setItem (key, val) {
        if (this.check(val) && this.check(key)) {
            this.storage.setItem(key, JSON.stringify({data: val}));
        }
    }

    getItem (key) {
        if (this.check(key)) {
            let val = this.storage.getItem(key);

            if (this.check(val)) {
                return JSON.parse(val).data;
            } else {
                return null;
            }
        }
    }

    removeItem (key) {
        if (this.check(key)) {
            this.storage.removeItem(key);
        }
    }

    clear () {
        this.storage.clear();
    }

    get length () {
        return this.storage.length;
    }

    key (num) {
        this.storage.key(num);
    }
}

const localStorage = new StorageUtil(true);
const sessionStorage = new StorageUtil(false);

export {
    localStorage, sessionStorage
};