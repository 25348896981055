import { AppAction } from '../actions';
import { storage } from '../../lib';

const {localStorage} = storage;
const {
    LOADING,
    ERROR,
    FINISH
} = AppAction;

const initialState = {
    pageTotal: 0,
    appList: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case LOADING: {
            return {
                ...state,
                error: {}
            };
        }
        case FINISH: {
            let result = {
                ...state,
                error: {}
            };
            if (action.payload) {
                result = {
                    ...result,
                    appList: action.payload.list,
                    pageTotal: action.payload.total
                }
            }
            return result;
        }
    }
    return {...state};
}