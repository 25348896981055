import React, { Component } from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import './style.scss';
// import Label from '../Label/Label';

function getDefaultApp () {
    return {
        name: '',
        icon: '',
        url: '',
        ticket: ''
    }
}

class ModalPrompt extends Component {

    static propTypes = {
        title: PropTypes.string,
        defaultApp: PropTypes.any,
        visible: PropTypes.bool,
        cancelCallback: PropTypes.func,
        okCallback: PropTypes.func
    };

    static defaultProps = {
        title: '',
        defaultApp: {},
        visible: false,
        cancelCallback: () => {},
        okCallback: () => {}
    };

    state = {
        confirmLoading: false,
        app: getDefaultApp(),
        errorText: ''
    };

    constructor (props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps (next) {
        let newApp = next.defaultApp;
        if (!Object.keys(newApp).length) {
            newApp = getDefaultApp();
        }
        this.setState({
            app: newApp
        });
    }

    handleChange (e) {
        const target = e.target;
        const key = target.dataset.propKey;
        const app = this.state.app;
        app[key] = target.value;
        this.setState({
            app
        });
    }

    handleCancel () {
        this.completeTask();
        this.props.cancelCallback && this.props.cancelCallback();
    }

    check () {
        const app = this.state.app;
        const filter = Object.keys(app).filter(v => {
            const val = app[v];
            return !val;
        });
        let errorText = '';
        if (filter.length > 0) {
            errorText = '请填写完整所有输入项';
        }
        this.setState({
            errorText
        });
        return !!errorText;
    }

    handleOk () {
        if (this.check()) {
            return;
        }
        // this.startTask();
        this.props.okCallback && this.props.okCallback(this.state.app, this.completeTask.bind(this));
    }

    startTask () {
        this.setState({
            confirmLoading: true
        });
    }

    completeTask () {
        this.setState({
            confirmLoading: false,
            app: getDefaultApp()
        });
    }

    render () {
        const {confirmLoading, app, errorText} = this.state;
        const {placeholder, visible, title, children, defaultApp} = this.props;
        return (
            <Modal
                title={title}
                visible={visible}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
            >
                {children}
                <div style={{marginBottom: 16}}>
                    <Input addonBefore="应用图标" value={app.icon} data-prop-key='icon' onChange={this.handleChange}
                           placeholder="请输入应用图标"/>
                </div>
                <div style={{marginBottom: 16}}>
                    <Input addonBefore="应用名称" value={app.name} data-prop-key='name' onChange={this.handleChange}
                           placeholder="请输入应用名称"/>
                </div>
                <div style={{marginBottom: 16}}>
                    <Input addonBefore="应用跳转地址" value={app.url} data-prop-key='url' onChange={this.handleChange}
                           placeholder="请输入应用跳转地址"/>
                </div>
                <div style={{marginBottom: 16}}>
                    <Input addonBefore="应用Ticket" value={app.ticket} data-prop-key='ticket' onChange={this.handleChange}
                           placeholder="请输入应用Ticket"/>
                </div>
                <p className="error-tips">{errorText}</p>
            </Modal>
        );
    }
}

export default ModalPrompt;