import React, { Component } from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';

// import Label from '../Label/Label';

class ModalPrompt extends Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.string,
        visible: PropTypes.bool,
        placeholder: PropTypes.string,
        cancelCallback: PropTypes.func,
        okCallback: PropTypes.func
    };

    static defaultProps = {
        title: '',
        defaultValue: '',
        visible: false,
        placeholder: '请输入内容',
        cancelCallback: () => {},
        okCallback: () => {}
    };

    state = {
        confirmLoading: false,
    };

    constructor (props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(next){
        this.setState({
            value: next.defaultValue
        })
    }

    handleChange (v) {
        this.setState({
            value: v.target.value
        });
    }

    handleCancel () {
        this.completeTask();
        this.props.cancelCallback && this.props.cancelCallback();
    }

    handleOk () {
        this.startTask();
        this.props.okCallback && this.props.okCallback(this.state.value, this.completeTask.bind(this));
    }

    startTask () {
        this.setState({
            confirmLoading: true
        });
    }

    completeTask () {
        this.setState({
            confirmLoading: false,
        });
    }

    render () {
        const {confirmLoading, value} = this.state;
        const {placeholder, visible, title, children, defaultValue} = this.props;
        return (
            <Modal
                title={title}
                visible={visible}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
            >
                {children}
                <Input value={value}  onChange={this.handleChange}
                       placeholder={placeholder}/>
            </Modal>
        );
    }
}

export default ModalPrompt;