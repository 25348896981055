import { AuthAction } from '../actions';
import { storage } from '../../lib';

const {localStorage} = storage;
const {
    LOADING,
    ERROR,
    FINISH,
    APP_LIST_FINISH,
    USER_LIST_FINISH
} = AuthAction;

const initialState = {
    pageTotal: 0,
    appList: [],
    userList: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case LOADING: {
            return {
                ...state,
                error: {}
            };
        }
        case FINISH: {
            return {
                ...state,
                error: {}
            };
        }
        case USER_LIST_FINISH: {
            let result = {
                ...state,
                error: {}
            };
            if (action.payload) {
                result = {
                    ...result,
                    pageTotal: action.payload.total,
                    userList: action.payload.list
                };
            }
            return result;
        }
        case APP_LIST_FINISH: {
            let result = {
                ...state,
                error: {}
            };
            if (action.payload) {
                result = {
                    ...result,
                    appList: action.payload.list,
                };
            }
            return result;
        }
    }
    return {...state};
}