import { fetch, config, storage } from '../../lib';
import { push } from 'react-router-redux';
import CommonAction from './common-action';

const {localStorage} = storage;

const {
    createStatus,
    handleError,
    fetchStatus
} = CommonAction;

const {ERROR, LOADING, FINISH} = createStatus('APP');

function handleGetAppListAction (limit, offset, keyword) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(config.getApps, {
            limit, offset, keyword
        }, {method: 'GET'}).then(v => {
            dispatch(fetchStatus(v.data, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleEditAppAction (app, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getApps}${app.id}`, app, {method: 'PUT'}).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleAddAppAction (app, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getApps}`, app).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleDeleteAppAction (uid, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getApps}${uid}`, {}, {method: 'DELETE'}).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

export default {
    LOADING,
    FINISH,
    ERROR,
    handleAddAppAction,
    handleDeleteAppAction,
    handleEditAppAction,
    handleGetAppListAction
};
