import { LoginAction } from '../actions';
import { storage } from '../../lib';

const {localStorage} = storage;
const {
    LOADING,
    ERROR,
    FINISH,
    LOGOUT,
    GET_APP_LOGIN,
    REDIRECT_OATH
} = LoginAction;

const initialState = {
    loginStatus: !!localStorage.getItem('login-user'),
    error: {},
    app: null,
    redirect: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case LOADING: {
            return {
                ...state,
                error: {}
            };
        }
        case FINISH: {
            const {user} = action.payload;
            return {
                ...state,
                user,
                error: {},
                loginStatus: true
            };
        }
        case LOGOUT: {
            return {
                ...state,
                loginStatus: false
            };
        }
        case GET_APP_LOGIN: {
            return {
                ...state,
                app: action.payload
            };
        }
        case REDIRECT_OATH: {
            return {
                ...state,
                redirect: true
            };
        }
    }
    return {...state};
}