import { fetch, config, storage } from '../../lib';
import { push } from 'react-router-redux';
import CommonAction from './common-action';

const {localStorage} = storage;

const {
    createStatus,
    handleError,
    fetchStatus
} = CommonAction;

const {ERROR, LOADING, FINISH} = createStatus('AUTH');
const USER_LIST_FINISH = 'USER_LIST_FINISH';
const APP_LIST_FINISH = 'APP_LIST_FINISH';

function handleGetAppListAction () {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getApps}all`, {}, {method: 'GET'}).then(v => {
            dispatch(fetchStatus(v.data, APP_LIST_FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleGetUserListAction (limit, offset, keyword) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(config.getUsers, {
            limit, offset, keyword, includes: true
        }, {method: 'GET'}).then(v => {
            dispatch(fetchStatus(v.data, USER_LIST_FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleEditUserAppAction (uid, appid, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getAuth}`, {
            uid, appid, status: 1
        }, {method: 'POST'}).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleDeleteUserAppAction (uid, appid, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getAuth}${uid}/${appid}`, {}, {method: 'DELETE'}).then(v => {
            fn();
            dispatch(fetchStatus(null, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

export default {
    LOADING,
    FINISH,
    ERROR,
    APP_LIST_FINISH,
    USER_LIST_FINISH,
    handleGetUserListAction,
    handleGetAppListAction,
    handleEditUserAppAction,
    handleDeleteUserAppAction
};
