import { fetch, config, storage } from '../../lib';
import { push } from 'react-router-redux';
import CommonAction from './common-action';

const {localStorage} = storage;

const {
    createStatus,
    handleError,
    fetchStatus
} = CommonAction;
const {ERROR, LOADING, FINISH} = createStatus('PERSONAL');
const GET_USER = 'GET_USER';
const EDIT_USER_FINISH = `EDIT_USER_${FINISH}`;
const EDIT_APP_FINISH = `EDIT_APP_${FINISH}`;

function getUserAction (data) {
    return {
        type: GET_USER,
        payload: data
    };
}

function handleGetUserAction () {
    return (dispatch, getState) => {
        const data = localStorage.getItem('login-user');
        return dispatch(getUserAction(data.user));
    };
}

function handleGetUserAppsAction () {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(config.apps, {}, {method: 'GET'}).then(data => {
            return dispatch(fetchStatus(data.data, FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleEditUserInfoAction (user, fn) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(config.editInfo, user, {method: 'PUT'}).then(data => {
            fn();
            const cache = localStorage.getItem('login-user');
            cache.user = user;
            localStorage.setItem('login-user', cache);
            return dispatch(fetchStatus(cache.user, EDIT_USER_FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

function handleUserAppStatusAction (appid, status) {
    return (dispatch, getState) => {
        dispatch(fetchStatus(null, LOADING));
        return fetch(`${config.getAuth}${appid}`, {
            status: Number(status)
        }, {method: 'PUT'}).then(data => {
            const state = getState();
            const result = state.personal.userApps.filter(val => val.appid === appid);
            result[0].status = Number(status);
            return dispatch(fetchStatus(state.personal.userApps, EDIT_APP_FINISH));
        }).catch(error => {
            handleError(ERROR)(dispatch, error);
        });
    };
}

export default {
    LOADING,
    FINISH,
    ERROR,
    GET_USER,
    EDIT_USER_FINISH,
    EDIT_APP_FINISH,
    handleGetUserAction,
    handleGetUserAppsAction,
    handleEditUserInfoAction,
    handleUserAppStatusAction
};
