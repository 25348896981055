export default {
    login: '/web/ignore/user/checklogin',
    logout: '/web/user/logout',
    apps: '/web/auth/user/apps',
    editInfo: '/web/user/',
    postImage: '/web/image/',
    getUsers: '/web/user/',
    getApps: '/web/apps/',
    getAuth: '/web/auth/',
    getApp2Login: '/web/ignore/apps/info/',
    gotoApp: '/web/apps/goto/'
}