import React, { Component } from 'react';
import { Table, Input, Button, Avatar, message } from 'antd';
import { ModalPrompt } from '../../widgets';
import { UserAction } from '../../../redux/actions';
import { connect } from 'react-redux';

const Search = Input.Search;

const {
    handleGetUserListAction,
    handleEditUserAction,
    handleDeleteUserAction,
    handleAddUserAction
} = UserAction;

const mapStateToProps = (state) => {
    const {user} = state;
    return {
        userList: user.userList,
        pageTotal: user.pageTotal
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleGetUserList: (limit, offset, keyword) => {
            dispatch(handleGetUserListAction(limit, offset, keyword));
        },
        handleEditUser: (user, fn) => {
            dispatch(handleEditUserAction(user, fn));
        },
        handleAddUser: (user, fn) => {
            dispatch(handleAddUserAction(user, fn));
        },
        handleDeleteUser: (user, fn) => {
            dispatch(handleDeleteUserAction(user, fn));
        }
    };
};

class UserManager extends Component {

    constructor (props) {
        super(props);
        this.state = {
            pageConfig: {
                size: 'small',
                total: this.props.pageTotal,
                showSizeChanger: true,
                showQuickJumper: true,
                current: 1,
                pageSize: 10,
            },
            modal: {
                title: '请输入：',
                visible: false,
                placeholder: '请输入账号',
                okCallback: (v, fn) => {
                },
                cancelCallback: () => {
                    const modal = this.state.modal;
                    modal.visible = false;
                    this.setState({
                        modal
                    });
                }
            }
        };
    }

    componentWillReceiveProps (n, o) {
        const pageConfig = this.state.pageConfig;
        pageConfig.total = n.pageTotal;
        this.setState({pageConfig});
    }

    componentDidMount () {
        this.props.handleGetUserList(this.state.pageConfig.pageSize, 0, '');
    }

    handleDelete = (e) => {
        const uid = e.target.dataset.uid;
        this.props.handleDeleteUser(uid, () => {
            message.success('删除用户成功');
            const pageConfig = this.state.pageConfig;
            pageConfig.current = 1;
            this.setState({
                pageConfig
            });
            this.props.handleGetUserList(pageConfig.pageSize, 0, '');
        });
    };

    handleSearch = (kw) => {
        this.props.handleGetUserList(this.state.pageConfig.pageSize, 0, kw);
    };

    handlePageChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetUserList(e.pageSize, (e.current - 1) * e.pageSize, '');
    };

    handlePageSizeChange = (e) => {
        this.setState({
            pageConfig: e
        });
        this.props.handleGetUserList(e.pageSize, 0, '');
    };

    handleBtnClick = (e) => {
        const modal = this.state.modal;
        modal.visible = true;
        modal.okCallback = (val, fn) => {
            this.props.handleAddUser({account: val}, () => {
                const modal = this.state.modal;
                modal.visible = false;
                this.setState({modal});
                fn();
                this.props.handleGetUserList(this.state.pageConfig.pageSize, 0, '');
                message.success(`添加用户成功`);
            });
        };
        this.setState({
            modal
        });
    };

    render () {

        const columns = [{
            title: '头像',
            dataIndex: 'avatar',
            key: 'avatar',
            width: 80,
            render: (text, v) => {
                let imageUrl = `/web/image/view/${v.headImg}`;
                return <Avatar icon='user' size={48} src={imageUrl}/>;
            }
        }, {
            title: '账号',
            dataIndex: 'account',
            key: 'account',
            width: 110
        }, {
            title: '昵称',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '职位',
            dataIndex: 'position',
            key: 'position',
        }, {
            title: '个性签名',
            dataIndex: 'signature',
            key: 'signature',
        }, {
            title: '标签',
            dataIndex: 'label',
            key: 'label',
        }, {
            title: '个人简介',
            dataIndex: 'introduce',
            key: 'introduce',
        }, {
            title: '操作',
            key: 'action',
            width: 70,
            render: (text, record) => (
                <span>
                    <a href="javascript:;" data-uid={record.id} onClick={this.handleDelete}>删除</a>
                </span>
            ),
        }
        ];
        const {keyword, pageConfig, modal} = this.state;
        const {userList} = this.props;

        return (
            <div className=' page-container component-user'>
                <div className="cat-info">
                    <div className="cat-name">
                        用户管理
                        <Button onClick={this.handleBtnClick} className='add-btn'>新增用户</Button>
                        <Search
                            defaultValue={keyword}
                            className='search-ipt'
                            placeholder="请输入要搜索的内容"
                            onSearch={this.handleSearch}
                            style={{width: 240}}
                        />
                    </div>
                </div>
                <div className="table-wrapper with-table-bordered">
                    <Table rowKey={record => record.id} onChange={this.handlePageChange}
                           onShowSizeChange={this.handlePageSizeChange} pagination={pageConfig}
                           dataSource={userList}
                           columns={columns}/>
                </div>
                <ModalPrompt {...modal}><p>请输入账号：</p></ModalPrompt>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManager);

